exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-catering-jsx": () => import("./../../../src/pages/catering.jsx" /* webpackChunkName: "component---src-pages-catering-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-speisekarte-jsx": () => import("./../../../src/pages/speisekarte.jsx" /* webpackChunkName: "component---src-pages-speisekarte-jsx" */)
}

